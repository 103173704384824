import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from './ui/table';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from './ui/dialog';
import { Alert, AlertDescription } from './ui/alert';
import CyberAssessment from './CyberAssessment';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:5001';

const AssessorPanel = ({ language }) => {
  const { user } = useContext(AuthContext);
  const [assignedUsers, setAssignedUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showAddUserForm, setShowAddUserForm] = useState(false);
  const [newUserName, setNewUserName] = useState('');
  const [newUserEmail, setNewUserEmail] = useState('');
  const [newUserPassword, setNewUserPassword] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    fetchAssignedUsers();
  }, []);

  const fetchAssignedUsers = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/api/assessor/assigned-users`, {
        headers: { 'x-auth-token': token }
      });
      if (response.ok) {
        const data = await response.json();
        setAssignedUsers(data);
      } else {
        throw new Error('Failed to fetch assigned users');
      }
    } catch (error) {
      setError('Error fetching assigned users: ' + error.message);
    }
  };

  const createNewUser = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/api/assessor/create-user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token
        },
        body: JSON.stringify({ name: newUserName, email: newUserEmail, password: newUserPassword })
      });
      if (response.ok) {
        const newUser = await response.json();
        setAssignedUsers([...assignedUsers, newUser]);
        setShowAddUserForm(false);
        setNewUserName('');
        setNewUserEmail('');
        setNewUserPassword('');
      } else {
        throw new Error('Failed to create user');
      }
    } catch (error) {
      setError('Error creating user: ' + error.message);
    }
  };

  const startNewAssessment = (user) => {
    setSelectedUser(user);
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Assessor Panel</h2>
      {error && <Alert variant="destructive"><AlertDescription>{error}</AlertDescription></Alert>}
      <Button onClick={() => setShowAddUserForm(true)} className="mb-4">Add New User</Button>
      <Card>
        <CardHeader>
          <CardTitle>Your Assigned Users</CardTitle>
        </CardHeader>
        <CardContent>
          {assignedUsers.length > 0 ? (
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Name</TableHead>
                  <TableHead>Email</TableHead>
                  <TableHead>Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {assignedUsers.map((user) => (
                  <TableRow key={user._id}>
                    <TableCell>{user.name}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>
                      <Dialog>
                        <DialogTrigger asChild>
                          <Button onClick={() => startNewAssessment(user)}>Start Assessment</Button>
                        </DialogTrigger>
                        <DialogContent className="max-w-4xl max-h-[80vh] overflow-y-auto">
                          <DialogHeader>
                            <DialogTitle>Assessment for {user.name}</DialogTitle>
                          </DialogHeader>
                          <CyberAssessment userId={user._id} isAssessor={true} language={language} />
                        </DialogContent>
                      </Dialog>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <p>No assigned users found. Add a new user to get started.</p>
          )}
        </CardContent>
      </Card>
      
      <Dialog open={showAddUserForm} onOpenChange={setShowAddUserForm}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Add New User</DialogTitle>
          </DialogHeader>
          <form onSubmit={createNewUser}>
            <Input
              type="text"
              placeholder="User Name"
              value={newUserName}
              onChange={(e) => setNewUserName(e.target.value)}
              className="mb-2"
            />
            <Input
              type="email"
              placeholder="User Email"
              value={newUserEmail}
              onChange={(e) => setNewUserEmail(e.target.value)}
              className="mb-2"
            />
            <Input
              type="password"
              placeholder="User Password"
              value={newUserPassword}
              onChange={(e) => setNewUserPassword(e.target.value)}
              className="mb-2"
            />
            <Button type="submit">Create User</Button>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AssessorPanel;
