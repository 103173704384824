export const loadQuestions = async (categories) => {
  let allQuestions = [];

  for (const category of categories) {
    try {
      console.log(`Attempting to load questions for category: ${category}`);
      const module = await import(`../questions/${category}.json`);
      const questions = module.default;
      if (Array.isArray(questions)) {
        console.log(`Successfully loaded ${questions.length} questions for category: ${category}`);
        console.log(`Sample question from ${category}:`, questions[0]);
        allQuestions = [...allQuestions, ...questions];
      } else {
        console.error(`Invalid question format for category: ${category}`);
      }
    } catch (error) {
      console.error(`Error loading questions for category: ${category}`, error);
    }
  }

  console.log(`Total questions loaded: ${allQuestions.length}`);
  console.log(`Sample of loaded questions:`, allQuestions.slice(0, 5));
  return allQuestions;
};
