import React, { useState, useEffect, useContext } from 'react';
import { AlertCircle, Save, Share, ChevronLeft, ChevronRight, FileDown } from 'lucide-react';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '../components/ui/card';
import { Button } from '../components/ui/button';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../components/ui/select';
import { Alert, AlertDescription, AlertTitle } from '../components/ui/alert';
import { RadioGroup, RadioGroupItem } from '../components/ui/radio-group';
import { Label } from '../components/ui/label';
import { Input } from '../components/ui/input';
import { Progress } from '../components/ui/progress';
import { Checkbox } from '../components/ui/checkbox';
import { PDFDownloadLink } from '@react-pdf/renderer';
import AssessmentPDF from './AssessmentPDF';
import OSINTDataHandler from './OSINTDataHandler';
import { analyzeResponses, assignMaturityScore, createActionPlan } from '../utils/chatgptUtils';
import { loadQuestions } from '../utils/questionLoader';
import translations from '../translations';
import { AuthContext } from '../contexts/AuthContext';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from './ui/dialog';
import { Tabs, TabsContent, TabsList, TabsTrigger } from './ui/tabs';
import { Textarea } from './ui/textarea';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:5001';
console.log('API_BASE_URL:', API_BASE_URL);

const CyberAssessment = ({ language, isAssessor = false }) => {
  const t = key => translations[language][key];
  const { user } = useContext(AuthContext);
  const [stage, setStage] = useState('setup');
  const [orgSize, setOrgSize] = useState('');
  const [orgSector, setOrgSector] = useState('');
  const [maturity, setMaturity] = useState('');
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState({});
  const [progress, setProgress] = useState(0);
  const [autoSaveInterval, setAutoSaveInterval] = useState(null);
  const [osintData, setOsintData] = useState(null);
  const [hasOsintData, setHasOsintData] = useState('no');
  const [showRecommendations, setShowRecommendations] = useState(false);
  const [showReportAndPromptDialog, setShowReportAndPromptDialog] = useState(false);
  const [selectedAssessment, setSelectedAssessment] = useState(null);
  const [promptInput, setPromptInput] = useState('');
  const [collaborationLink, setCollaborationLink] = useState('');
  const [questions, setQuestions] = useState([]);
  const [error, setError] = useState(null);
  const [optInAdvanced, setOptInAdvanced] = useState(false);
  const [showAdvancedQuestions, setShowAdvancedQuestions] = useState(false);
  const [assessment, setAssessment] = useState(null);
  const [reportRequested, setReportRequested] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [selectedFramework, setSelectedFramework] = useState('');
  const [helperData, setHelperData] = useState(null);

// Add helper dialogues to Assessor Panel
  useEffect(() => {
    if (isAssessor && currentQuestion < questions.length) {
      fetchHelperData(questions[currentQuestion].id);
    }
  }, [isAssessor, currentQuestion, questions]);

  const fetchHelperData = async (questionId) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/questions/helper/${questionId}`);
      if (response.ok) {
        const data = await response.json();
        setHelperData(data);
      }
    } catch (error) {
      console.error('Error fetching helper data:', error);
    }
  };

useEffect(() => {
    const fetchAssessment = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${API_BASE_URL}/api/assessments`, {
          headers: {
            'x-auth-token': token
          }
        });
        if (response.ok) {
          const data = await response.json();
          setAssessment(data);
          setAnswers(data.answers || {});
          setOrgSize(data.orgSize || '');
          setOrgSector(data.orgSector || '');
          setMaturity(data.maturity || '');
          setSelectedFramework(data.selectedFramework || '');
        } else if (response.status === 404) {
          console.log('No existing assessment found');
        } else {
          console.error('Failed to fetch assessment:', await response.text());
        }
      } catch (error) {
        console.error('Error fetching assessment:', error);
        setError('Failed to fetch existing assessment. Please try again.');
      }
    };
    fetchAssessment();

    const savedAnswers = localStorage.getItem('cyberAssessmentAnswers');
    if (savedAnswers) {
      setAnswers(JSON.parse(savedAnswers));
    }
    const savedOrgSize = localStorage.getItem('orgSize');
    if (savedOrgSize) {
      setOrgSize(savedOrgSize);
    }
    const savedOrgSector = localStorage.getItem('orgSector');
    if (savedOrgSector) {
      setOrgSector(savedOrgSector);
    }
    const savedMaturity = localStorage.getItem('maturity');
    if (savedMaturity) {
      setMaturity(savedMaturity);
    }
    const savedFramework = localStorage.getItem('selectedFramework');
    if (savedFramework) {
      setSelectedFramework(savedFramework);
    }
  }, []);

  useEffect(() => {
    updateProgress();
  }, [stage, currentQuestion, orgSize, orgSector, answers, questions, maturity, selectedFramework]);

  useEffect(() => {
    const interval = setInterval(() => {
      handleSaveProgress();
    }, 60000); // Autosave every 60 seconds

    setAutoSaveInterval(interval);

    return () => clearInterval(interval);
  }, [answers, orgSize, orgSector, maturity, selectedFramework]);

useEffect(() => {
  const fetchQuestions = async () => {
    try {
      const categories = ['general'];
      if (orgSize) categories.push(`size-${orgSize.toLowerCase()}`);
      if (orgSector) categories.push(`sector-${orgSector.toLowerCase()}`);
      if (maturity) categories.push(`maturity-${maturity.toLowerCase()}`);
      if (selectedFramework) categories.push(`framework-${selectedFramework.toLowerCase()}`);
      console.log('Fetching categories:', categories);
      const loadedQuestions = await loadQuestions(categories);
      console.log('Loaded questions:', loadedQuestions);
      if (loadedQuestions.length === 0) {
        setError('No questions loaded. Please check your question files.');
      } else {
        setQuestions(loadedQuestions);
        setError(null);
      }
    } catch (error) {
      console.error('Error fetching questions:', error);
      setError('Failed to load questions. Please try again later.');
    }
  };

  if (orgSize && orgSector && maturity && selectedFramework) {
    fetchQuestions();
  }
}, [orgSize, orgSector, maturity, selectedFramework]);

const viewFullReport = async (assessmentId) => {
  try {
    const token = localStorage.getItem('token');
    const url = `${API_BASE_URL}/api/assessments/${assessmentId}/report`;
    const response = await fetch(url, {
      headers: { 'x-auth-token': token }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const reportData = await response.json();
    
    // Fetch all questions
    const allCategories = ['general', `size-${reportData.orgSize.toLowerCase()}`, `sector-${reportData.orgSector.toLowerCase()}`];
    
    // Add maturity category if it exists
    if (reportData.maturity) {
      allCategories.push(`maturity-${reportData.maturity.toLowerCase()}`);
    }
    
    // Add framework category if it exists
    if (reportData.selectedFramework) {
      allCategories.push(`framework-${reportData.selectedFramework.toLowerCase()}`);
    }
    
    const allQuestions = await loadQuestions(allCategories);

    setSelectedAssessment({...reportData, questions: allQuestions});
    console.log('Full report data:', {...reportData, questions: allQuestions});
  } catch (error) {
    console.error('Error fetching full report:', error);
    setError(`Failed to fetch full report: ${error.message}`);
  }
};

  const updateProgress = () => {
    if (questions.length > 0) {
      setProgress((currentQuestion / questions.length) * 100);
    } else {
      setProgress(0);
    }
  };

const handleAdvancedQuestions = async () => {
  setShowAdvancedQuestions(true);
  try {
    const advancedQuestions = await loadQuestions(['advanced']);
    console.log('Loaded advanced questions:', advancedQuestions);
    if (advancedQuestions.length > 0) {
      setQuestions(prevQuestions => [...prevQuestions, ...advancedQuestions]);
    } else {
      setError('No advanced questions available.');
    }
  } catch (error) {
    console.error('Error loading advanced questions:', error);
    setError('Failed to load advanced questions. Please try again.');
  }
};

const handleFrameworkSelection = async (framework) => {
  setSelectedFramework(framework);
  const frameworkQuestions = await loadQuestions([`framework-${framework.toLowerCase()}`]);
  setQuestions(prevQuestions => {
    const nonDuplicateQuestions = prevQuestions.filter(q => !frameworkQuestions.some(fq => fq.id === q.id));
    return [...frameworkQuestions, ...nonDuplicateQuestions];
  });
};

const handleSaveProgress = async () => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      setError('No authentication token found. Please log in again.');
      return;
    }
    console.log('Saving assessment with data:', { answers, orgSize, orgSector, maturity, selectedFramework, language });
    const response = await fetch(`${API_BASE_URL}/api/assessments`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token,
      },
      body: JSON.stringify({
        answers,
        orgSize,
        orgSector,
        maturity,
        selectedFramework,
        language,
      }),
    });

    if (response.ok) {
      console.log('Assessment saved successfully');
      setError('');
    } else if (response.status === 401) {
      setError('Your session has expired. Please log in again.');
      // Optionally, redirect to login page or trigger a re-authentication flow
    } else {
      console.error('Failed to save assessment', await response.text());
      setError('Failed to save progress. Please try again.');
    }
  } catch (error) {
    console.error('Error saving assessment:', error);
    setError('Error saving progress. Please check your connection and try again.');
  }
};

useEffect(() => {
  const autoSaveInterval = setInterval(() => {
    if (Object.keys(answers).length > 0) {
      handleSaveProgress();
    }
  }, 60000); // Auto-save every 60 seconds

  return () => clearInterval(autoSaveInterval);
}, [answers, orgSize, orgSector]);

  const handleShare = () => {
    const link = `https://example.com/share?answers=${encodeURIComponent(JSON.stringify(answers))}&orgSize=${orgSize}&orgSector=${orgSector}`;
    setCollaborationLink(link);
  };

const handleNextQuestion = () => {
  if (currentQuestion < questions.length - 1) {
    setCurrentQuestion(currentQuestion + 1);
  } else if (!showAdvancedQuestions && optInAdvanced) {
    handleAdvancedQuestions();
  } else {
    setShowRecommendations(true);
    setStage('completed');
  }
};

  const handlePreviousQuestion = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
    }
  };

  const handleAnswerChange = (e, questionId) => {
    setAnswers({
      ...answers,
      [questionId]: e.target.value
    });
  };

const handleRequestFullReport = async () => {
  setError(null);
  try {
    if (!assessment) {
      throw new Error('No assessment found');
    }
    const token = localStorage.getItem('token');
    const response = await fetch(`${API_BASE_URL}/api/assessments/request-report`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token
      },
      body: JSON.stringify({ assessmentId: assessment._id })
    });

    if (!response.ok) {
      throw new Error('Failed to request report');
    }

    setSuccessMessage('Your report has been requested successfully. An admin will review and generate it soon.');
  } catch (error) {
    console.error('Error requesting full report:', error);
    setError('An error occurred while requesting the full report. Please try again.');
  }
};

const viewFullReportAndPrompt = async (assessmentId) => {
  try {
    const token = localStorage.getItem('token');
    const url = `${API_BASE_URL}/api/assessments/${assessmentId}/report`;
    const response = await fetch(url, {
      headers: { 'x-auth-token': token }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const reportData = await response.json();
    
    const allCategories = [
      'general', 'advanced',
      `size-${reportData.orgSize.toLowerCase()}`,
      `sector-${reportData.orgSector.toLowerCase()}`,
      `maturity-${reportData.maturity.toLowerCase()}`,
      `framework-${reportData.selectedFramework.toLowerCase()}`
    ].filter(Boolean);
    
    const allQuestions = await loadQuestions(allCategories);

    setSelectedAssessment({...reportData, questions: allQuestions});

    const promptResponse = await fetch(`${API_BASE_URL}/api/assessments/${assessmentId}/review`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token
      },
      body: JSON.stringify({ 
        action: 'get_prompt',
        assessment: reportData,
        questions: allQuestions
      })
    });

    if (!promptResponse.ok) {
      throw new Error('Failed to get prompt');
    }

    const promptData = await promptResponse.json();
    setPromptInput(promptData.prompt);
    setShowReportAndPromptDialog(true);

  } catch (error) {
    console.error('Error fetching full report and prompt:', error);
    setError(`Failed to fetch full report and prompt: ${error.message}`);
  }
};

  const handleOsintDataLoaded = (data) => {
    setOsintData(data);
    setStage('assessment');
  };

  const renderQuestionInput = (question) => {
    switch (question.type) {
      case 'text':
        return (
          <textarea
            className="w-full p-2 border rounded"
            rows="4"
            value={answers[question.id] || ''}
            onChange={(e) => handleAnswerChange(e, question.id)}
            placeholder={t('typeAnswerHere')}
          />
        );
      case 'number':
        return (
          <Input
            type="number"
            value={answers[question.id] || ''}
            onChange={(e) => handleAnswerChange(e, question.id)}
            placeholder={t('enterNumber')}
          />
        );
      case 'boolean':
        return (
          <RadioGroup
            value={answers[question.id] || ''}
            onValueChange={(value) => handleAnswerChange({ target: { value } }, question.id)}
          >
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="true" id={`${question.id}-yes`} />
              <Label htmlFor={`${question.id}-yes`}>{t('yes')}</Label>
            </div>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="false" id={`${question.id}-no`} />
              <Label htmlFor={`${question.id}-no`}>{t('no')}</Label>
            </div>
          </RadioGroup>
        );
      case 'select':
        return (
          <Select
            value={answers[question.id] || ''}
            onValueChange={(value) => handleAnswerChange({ target: { value } }, question.id)}
          >
            <SelectTrigger className="w-full">
              <SelectValue placeholder={t('selectOption')} />
            </SelectTrigger>
            <SelectContent>
              {question.options[language].map((option) => (
                <SelectItem key={option} value={option}>
                  {option}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        );
      default:
        return null;
    }
  };

const renderSetup = () => (
    <Card className="max-w-2xl mx-auto">
      <CardHeader>
        <CardTitle>{t('organizationSetup')}</CardTitle>
        <CardDescription>{t('pleaseProvide')}</CardDescription>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          <div>
            <Label htmlFor="orgSize">{t('organizationSize')}</Label>
            <Select value={orgSize} onValueChange={setOrgSize}>
              <SelectTrigger id="orgSize">
                <SelectValue placeholder={t('selectSize')} />
              </SelectTrigger>
              <SelectContent>
                {['Small', 'Medium', 'Large'].map((size) => (
                  <SelectItem key={size} value={size}>{t(size.toLowerCase())}</SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div>
            <Label htmlFor="orgSector">{t('organizationSector')}</Label>
            <Select value={orgSector} onValueChange={setOrgSector}>
              <SelectTrigger id="orgSector">
                <SelectValue placeholder={t('selectSector')} />
              </SelectTrigger>
              <SelectContent>
                  {['Finance', 'Healthcare', 'Technology', 'Retail', 'Manufacturing', 'Government', 'Education', 'Energy', 'Telecom', 'Transportation'].map((sector) => (
                  <SelectItem key={sector} value={sector}>{t(sector.toLowerCase())}</SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
<div>
  <Label htmlFor="maturity">{t('organizationMaturity')}</Label>
  <Select value={maturity} onValueChange={setMaturity}>
    <SelectTrigger id="maturity">
      <SelectValue placeholder={t('selectMaturity')} />
    </SelectTrigger>
    <SelectContent>
      {['Basic', 'Intermediate', 'Advanced'].map((level) => (
        <SelectItem key={level} value={level.toLowerCase()}>{t(level.toLowerCase())}</SelectItem>
      ))}
    </SelectContent>
  </Select>
</div>
        <div>
          <Label htmlFor="framework">{t('organizationFramework')}</Label>
          <Select value={selectedFramework} onValueChange={setSelectedFramework}>
            <SelectTrigger id="framework">
              <SelectValue placeholder={t('selectFramework')} />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="framework-nist171">NIST SP 800-171</SelectItem>
              <SelectItem value="framework-iso27001">ISO/IEC 27001</SelectItem>
              <SelectItem value="framework-cis">CIS Controls</SelectItem>
              <SelectItem value="framework-basiccompliance">Basic Compliance</SelectItem>
            </SelectContent>
          </Select>
        </div>
          <div>
            <Label>{t('haveOsintData')}</Label>
            <RadioGroup onValueChange={setHasOsintData} value={hasOsintData}>
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="yes" id="osint-yes" />
                <Label htmlFor="osint-yes">{t('yes')}</Label>
              </div>
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="no" id="osint-no" />
                <Label htmlFor="osint-no">{t('no')}</Label>
              </div>
            </RadioGroup>
          </div>
        </div>
      </CardContent>
      <CardFooter>
        <Button 
          onClick={() => setStage(hasOsintData === 'yes' ? 'osint' : 'assessment')}
          disabled={!orgSize || !orgSector || !selectedFramework}
          className="w-full"
        >
          {hasOsintData === 'yes' ? t('uploadOsintData') : t('startAssessment')}
        </Button>
      </CardFooter>
    </Card>
  );

  const renderOsintUpload = () => (
    <Card>
      <CardHeader>
        <CardTitle>{t('uploadOsintData')}</CardTitle>
        <CardDescription>{t('uploadOsintDescription')}</CardDescription>
      </CardHeader>
      <CardContent>
        <OSINTDataHandler onDataLoaded={handleOsintDataLoaded} language={language} />
      </CardContent>
      <CardFooter>
        <Button onClick={() => setStage('assessment')}>{t('skipOsintUpload')}</Button>
      </CardFooter>
    </Card>
  );

const renderQuestion = () => {
  if (questions.length === 0) {
    return (
      <Card className="max-w-2xl mx-auto">
        <CardContent>
          <p>{t('noQuestionsAvailable')}</p>
        </CardContent>
      </Card>
    );
  }
  
  const currentQuestionData = questions[currentQuestion];
  if (!currentQuestionData) {
    console.error('Current question is undefined:', currentQuestion, questions);
    return (
      <Card className="max-w-2xl mx-auto">
        <CardContent>
          <p>{t('errorLoadingQuestion')}</p>
        </CardContent>
      </Card>
    );
  }

  const isLastQuestion = currentQuestion === questions.length - 1;

  return (
    <Card className="max-w-2xl mx-auto">
      <CardHeader>
        <CardTitle>{t('cyberAssessment')}</CardTitle>
        <CardDescription>
          {t('questionNumber').replace('{current}', currentQuestion + 1).replace('{total}', questions.length)}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Progress value={progress} className="mb-4" />
        <p className="mb-4 font-medium">
          {currentQuestionData.text && currentQuestionData.text[language] ? 
            currentQuestionData.text[language] : 
            t('questionNotAvailable')}
        </p>
        {renderQuestionInput(currentQuestionData)}
        {isLastQuestion && !showAdvancedQuestions && (
          <div className="mt-4">
            <Checkbox 
              id="optInAdvanced"
              checked={optInAdvanced}
              onCheckedChange={(checked) => setOptInAdvanced(checked)}
            />
            <Label htmlFor="optInAdvanced" className="ml-2">
              {t('optInAdvanced')}
            </Label>
          </div>
        )}
        {isAssessor && helperData && (
          <div className="mt-4 p-4 bg-gray-100 rounded-md">
            <h4 className="font-semibold mb-2">{t('assessorHelperData')}</h4>
            <details>
              <summary className="cursor-pointer font-medium">{t('assessmentGuidance')}</summary>
              <p className="mt-2">{helperData.assessment_guidance[language]}</p>
            </details>
            <details className="mt-2">
              <summary className="cursor-pointer font-medium">{t('evidenceExamples')}</summary>
              <ul className="mt-2 list-disc list-inside">
                {helperData.evidence_examples[language].map((example, index) => (
                  <li key={index}>{example}</li>
                ))}
              </ul>
            </details>
            <details className="mt-2">
              <summary className="cursor-pointer font-medium">{t('scoringCriteria')}</summary>
              <p className="mt-2">{helperData.scoring_criteria[language]}</p>
            </details>
          </div>
        )}
      </CardContent>
      <CardFooter className="flex justify-between">
        <Button onClick={handlePreviousQuestion} disabled={currentQuestion === 0} variant="outline">
          <ChevronLeft className="mr-2 h-4 w-4" />
          {t('previous')}
        </Button>
        <Button onClick={handleNextQuestion}>
          {isLastQuestion && !optInAdvanced ? t('finish') : t('next')}
          <ChevronRight className="ml-2 h-4 w-4" />
        </Button>
      </CardFooter>
    </Card>
  );
};

const renderRecommendations = () => {
  return (
    <Card className="max-w-2xl mx-auto">
      <CardHeader>
        <CardTitle>{t('recommendations')}</CardTitle>
        <CardDescription>{t('recommendationsDescription')}</CardDescription>
      </CardHeader>
      <CardContent>
        {!reportRequested ? (
          <Button 
            onClick={handleRequestFullReport} 
            disabled={reportRequested}
            className="w-full mb-4"
          >
            {reportRequested ? t('requestingReport') : t('requestFullReport')}
          </Button>
        ) : (
          <Alert>
            <AlertTitle>Success</AlertTitle>
            <AlertDescription>{successMessage}</AlertDescription>
          </Alert>
        )}
      </CardContent>
      <CardFooter className="flex justify-between">
        <Button 
          onClick={() => {
            setShowRecommendations(false);
            setStage('setup');
            setCurrentQuestion(0);
            setAnswers({});
          }} 
          variant="outline"
        >
          {t('startNewAssessment')}
        </Button>
        {assessment && (
          <PDFDownloadLink
            document={
              <AssessmentPDF
                orgSize={assessment.orgSize}
                orgSector={assessment.orgSector}
                answers={assessment.answers}
                questions={questions}
                language={language}
                osintData={assessment.osintData}
                fullReport={assessment}
                user={assessment.user}
              />
            }
            fileName={`assessment_report_${assessment.user.customerIdentifier}.pdf`}
          >
            {({ blob, url, loading, error }) => (
              <Button disabled={loading} className="ml-2">
                <FileDown className="mr-2 h-4 w-4" />
                {loading ? 'Loading document...' : 'Download PDF'}
              </Button>
            )}
          </PDFDownloadLink>
        )}
      </CardFooter>
    </Card>
  );
};

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold">{t('cyberAssessment')}</h1>
        <div className="flex items-center">
          <Button onClick={handleSaveProgress} className="ml-2">
            <Save className="mr-2 h-4 w-4" /> {t('save')}
          </Button>
          <Button onClick={handleShare} className="ml-2">
            <Share className="mr-2 h-4 w-4" /> {t('share')}
          </Button>
        </div>
      </div>
      
      {collaborationLink && (
        <Alert className="mb-4">
          <AlertCircle className="h-4 w-4" />
          <AlertTitle>{t('collaborationLinkGenerated')}</AlertTitle>
          <AlertDescription>
            {t('shareToCollaborate')}: 
            <a href={collaborationLink} className="underline">{collaborationLink}</a>
          </AlertDescription>
        </Alert>
      )}

      {error && (
        <Alert variant="destructive" className="mb-4">
          <AlertCircle className="h-4 w-4" />
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}
      
      <div className="mt-8">
        {stage === 'setup' && renderSetup()}
        {stage === 'osint' && renderOsintUpload()}
        {stage === 'assessment' && !showRecommendations && renderQuestion()}
        {showRecommendations && renderRecommendations()}
      </div>
    </div>
  );
};

export default CyberAssessment;
