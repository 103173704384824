const translations = {
  en: {
    // General
    cyberAssessment: "Cyber Assessment",
    save: "Save",
    share: "Share",
    yes: "Yes",
    no: "No",
    next: "Next",
    previous: "Previous",
    finish: "Finish",

    // Organization Setup
    organizationSetup: "Organization Setup",
    pleaseProvide: "Please provide information about your organization",
    organizationSize: "Organization Size",
    selectSize: "Select size",
    small: "Small",
    medium: "Medium",
    large: "Large",
    organizationSector: "Organization Sector",
    selectSector: "Select sector",
    finance: "Finance",
    healthcare: "Healthcare",
    technology: "Technology",
    retail: "Retail",
    manufacturing: "Manufacturing",
    government: "Government",
    education: "Education",
    energy: "Energy",
    telecom: "Telecommunications",
    transportation: "Transportation",
    haveOsintData: "Do you have OSINT data to upload?",
    uploadOsintData: "Upload OSINT Data",
    startAssessment: "Start Assessment",
    questionNumber: "Question {current} of {total}",
    typeAnswerHere: "Type your answer here...",
    noQuestionsAvailable: "No questions available for the selected criteria.",
    analyzeResponses: "Analyze Responses",
    assignMaturityScore: "Assign Maturity Score",
    createActionPlan: "Create Action Plan",
    downloadPdf: "Download PDF Report",
    maturityScore: "Maturity Score: {score}",
    actionPlan: "Action Plan",
    organizationMaturity: "Organization Maturity",
    selectMaturity: "Select maturity level",
    basic: "Basic",
    intermediate: "Intermediate",
    advanced: "Advanced",

    // OSINT Upload
    uploadOsintDescription: "Upload your OSINT data Excel file",
    skipOsintUpload: "Skip OSINT Upload",

    // Assessment
    questionNumber: "Question {current} of {total}",
    typeAnswerHere: "Type your answer here...",
    downloadPdf: "Download PDF Report",
    analyzeResponses: "Analyze Responses",
    assignMaturityScore: "Assign Maturity Score",
    createActionPlan: "Create Action Plan",
    enterNumber: "Enter a number",
    optInAdvanced: "Would you like to answer additional advanced questions?",
    selectOption: "Select an option",
    organizationFramework: "Cybersecurity Framework",
    selectFramework: "Select Framework",
    NIST171: "NIST SP 800-171",
    ISO27001: "ISO/IEC 27001",
    CIS: "CIS Controls",
    BASICCOMPLIANCE: "Basic Compliance",
    assessorHelperData: "Assessor Helper Data",
    assessmentGuidance: "Assessment Guidance",
    evidenceExamples: "Evidence Examples",
    scoringCriteria: "Scoring Criteria",

    // Recommendations
    recommendations: "Recommendations",
    recommendationsDescription: "Based on your responses, we recommend the following actions:",
    startNewAssessment: "Start New Assessment",
    downloadPdf: "Download PDF Report",

    // Analysis
    analyzeResponses: "Analyze Responses",
    assignMaturityScore: "Assign Maturity Score",
    createActionPlan: "Create Action Plan",
    analysisResults: "Analysis Results",
    requestFullReport: "Request Full Report and Action Plan",
    generatingReport: "Generating Report...",

    // Misc
    progressSaved: "Progress saved successfully!",
    collaborationLinkGenerated: "Collaboration Link Generated",
    shareToCollaborate: "Share this link to collaborate",
    disclaimer: 'CONFIDENTIAL: This document contains proprietary information and may not be shared without written permission from Cyber Celeste, LLC.',
  },
  es: {
    // General
    cyberAssessment: "Evaluación de Ciberseguridad",
    save: "Guardar",
    share: "Compartir",
    yes: "Sí",
    no: "No",
    next: "Siguiente",
    previous: "Anterior",
    finish: "Finalizar",

    // Organization Setup
    organizationSetup: "Configuración de la Organización",
    pleaseProvide: "Por favor, proporcione información sobre su organización",
    organizationSize: "Tamaño de la Organización",
    selectSize: "Seleccionar tamaño",
    small: "Pequeña",
    medium: "Mediana",
    large: "Grande",
    organizationSector: "Sector de la Organización",
    selectSector: "Seleccionar sector",
    finance: "Finanzas",
    healthcare: "Salud",
    technology: "Tecnología",
    retail: "Comercio Minorista",
    manufacturing: "Manufactura",
    government: "Gobierno",
    education: "Educación",
    energy: "Energía",
    telecom: "Telecomunicaciones",
    transportation: "Transporte",
    haveOsintData: "¿Tiene datos OSINT para cargar?",
    uploadOsintData: "Cargar Datos OSINT",
    startAssessment: "Iniciar Evaluación",
    questionNumber: "Pregunta {current} de {total}",
    typeAnswerHere: "Escriba su respuesta aquí...",
    noQuestionsAvailable: "No hay preguntas disponibles para los criterios seleccionados.",
    analyzeResponses: "Analizar Respuestas",
    assignMaturityScore: "Asignar Puntuación de Madurez",
    createActionPlan: "Crear Plan de Acción",
    downloadPdf: "Descargar Informe PDF",
    maturityScore: "Puntuación de Madurez: {score}",
    actionPlan: "Plan de Acción",
    organizationMaturity: "Madurez de la Organización",
    selectMaturity: "Seleccione el nivel de madurez",
    basic: "Básico",
    intermediate: "Intermedio",
    advanced: "Avanzado",

    // OSINT Upload
    uploadOsintDescription: "Cargue su archivo Excel de datos OSINT",
    skipOsintUpload: "Omitir Carga OSINT",

    // Assessment
    questionNumber: "Pregunta {current} de {total}",
    typeAnswerHere: "Escriba su respuesta aquí...",
    downloadPdf: "Descargar Informe PDF",
    analyzeResponses: "Analizar Respuestas",
    assignMaturityScore: "Asignar Puntuación de Madurez",
    createActionPlan: "Crear Plan de Acción",
    enterNumber: "Ingrese un número",
    optInAdvanced: "¿Le gustaría responder preguntas adicionales avanzadas?",
    selectOption: "Seleccione una opción",
    organizationFramework: "Marco de Ciberseguridad",
    selectFramework: "Seleccionar un marco",
    NIST171: "NIST SP 800-171",
    ISO27001: "ISO/IEC 27001",
    CIS: "Controles CIS",
    BASICCOMPLIANCE: "Cumplimiento Básico",
    assessorHelperData: "Datos de Ayuda para el Evaluador",
    assessmentGuidance: "Guía de Evaluación",
    evidenceExamples: "Ejemplos de Evidencia",
    scoringCriteria: "Criterios de Puntuación",

    // Recommendations
    recommendations: "Recomendaciones",
    recommendationsDescription: "Basado en sus respuestas, recomendamos las siguientes acciones:",
    startNewAssessment: "Iniciar Nueva Evaluación",
    downloadPdf: "Descargar Informe PDF",

    // Analysis
    analyzeResponses: "Analizar Respuestas",
    assignMaturityScore: "Asignar Puntuación de Madurez",
    createActionPlan: "Crear Plan de Acción",
    analysisResults: "Resultados del Análisis",
    requestFullReport: "Solicitar Informe Completo y Plan de Acción",
    generatingReport: "Generando Informe...",

    // Misc
    progressSaved: "¡Progreso guardado con éxito!",
    collaborationLinkGenerated: "Enlace de Colaboración Generado",
    shareToCollaborate: "Comparta este enlace para colaborar",
    disclaimer: 'CONFIDENCIAL: Este documento contiene información propietaria y no puede ser compartido sin permiso escrito de Cyber Celeste, LLC.',
  }
};

export default translations;
