import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#E4E4E4',
    padding: 30,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    marginBottom: 30,
  },
  subtitle: {
    fontSize: 18,
    marginBottom: 10,
  },
  text: {
    fontSize: 12,
    marginBottom: 5,
  },
  questionSection: {
    marginBottom: 20,
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#000',
    borderBottomStyle: 'solid',
    alignItems: 'center',
    height: 24,
    fontStyle: 'bold',
  },
  tableCol: {
    width: '50%',
  },
  tableCell: {
    margin: 'auto',
    fontSize: 10,
  },
  disclaimer: {
    fontSize: 10,
    marginTop: 20,
    textAlign: 'center',
    fontStyle: 'italic',
  },
});

const AssessmentPDF = ({ 
  orgSize, 
  orgSector, 
  answers, 
  questions,
  language,
  osintData,
  fullReport,
  user
}) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.title}>{language === 'en' ? 'Cyber Assessment Report' : 'Informe de Evaluación de Ciberseguridad'}</Text>
        
        <Text style={styles.subtitle}>{language === 'en' ? 'Organization Details:' : 'Detalles de la Organización:'}</Text>
        <Text style={styles.text}>{language === 'en' ? 'Size: ' : 'Tamaño: '}{orgSize || 'N/A'}</Text>
        <Text style={styles.text}>{language === 'en' ? 'Sector: ' : 'Sector: '}{orgSector || 'N/A'}</Text>
        
        {fullReport ? (
          <>
            <Text style={styles.subtitle}>{language === 'en' ? 'Analysis Results:' : 'Resultados del Análisis:'}</Text>
            <Text style={styles.text}>{fullReport.analysisResult || 'No analysis results available'}</Text>
            
            <Text style={styles.subtitle}>{language === 'en' ? 'Maturity Score:' : 'Puntuación de Madurez:'}</Text>
            <Text style={styles.text}>{fullReport.maturityScore || 'No maturity score available'}</Text>
            
            <Text style={styles.subtitle}>{language === 'en' ? 'Action Plan:' : 'Plan de Acción:'}</Text>
            <Text style={styles.text}>{fullReport.actionPlan || 'No action plan available'}</Text>
          </>
        ) : (
          <>
            <Text style={styles.subtitle}>{language === 'en' ? 'Assessment Responses:' : 'Respuestas de la Evaluación:'}</Text>
            {Object.entries(answers || {}).map(([questionId, answer], index) => {
              const question = questions.find(q => q.id === questionId);
              return (
                <View key={index} style={styles.questionSection}>
                  <Text style={styles.text}>
                    {language === 'en' ? 'Question ' : 'Pregunta '}{index + 1}: {question ? question.text[language] : 'Unknown question'}
                  </Text>
                  <Text style={styles.text}>{language === 'en' ? 'Answer: ' : 'Respuesta: '}{answer || 'Not answered'}</Text>
                </View>
              );
            })}
          </>
        )}
        
        {osintData && (
          <>
            <Text style={styles.subtitle}>{language === 'en' ? 'OSINT Data:' : 'Datos OSINT:'}</Text>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{language === 'en' ? 'Key' : 'Clave'}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{language === 'en' ? 'Value' : 'Valor'}</Text>
              </View>
            </View>
            {Object.entries(osintData).map(([key, value], index) => (
              <View style={styles.tableRow} key={index}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{key}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{value}</Text>
                </View>
              </View>
            ))}
          </>
        )}

        <Text style={styles.disclaimer}>
          {language === 'en' 
            ? 'CONFIDENTIAL: This document contains proprietary information and may not be shared without written permission from Cyber Celeste, LLC.' 
            : 'CONFIDENCIAL: Este documento contiene información propietaria y no puede ser compartido sin permiso escrito de Cyber Celeste, LLC.'}
        </Text>
      </View>
    </Page>
  </Document>
);

export default AssessmentPDF;
